"use strict";

function getSpiner() {
    let spiner = document.getElementById('spinner');
    if (!spiner) {

        spiner = document.createElement('div');
        spiner.id = 'spinner';
        spiner.classList.add('spinner');

        spiner.innerHTML = `
            <div class="spinner-content">
                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                    repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
                <p id="spinner-text">Loading ... </p>
            </div>`;
        document.body.appendChild(spiner);
    }
    return spiner;
}

export default class Spinner {

    static show(msg = 'Loading ...') {
        const spiner = getSpiner();
        const spinertext = document.getElementById('spinner-text');
        spinertext.innerText = msg;
        spiner.classList.toggle('d-none', false);
    }

    static hide() {
        const spiner = getSpiner();
        spiner.remove();
    }
}