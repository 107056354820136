'use strict';

/**
 *
 * this.$usersType = ['A', 'R', 'L', 'G', 'S', 'SG', 'M', 'J', 'O', 'P', 'I', 'H', 'C'];
 *
 *
 *
 *
 *
 * +-- ROOT
 * |
 * |  +-- HOSTMASTER
 * |  |
 * |  |  +-- EMPLOYEE
 * |  |  |
 * |  |  |  +-- EMPLOYEE
 * |  |  |  |
 * |  |  |  |  +-- UNDEFINED6
 * |  |  |  |  |
 * |  |  |  |  |  +-- RESELLER
 * |  |  |  |  |  |
 * |  |  |  |  |  |  +-- MASTER
 * |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  +-- PUBLISHER
 * |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  +-- MODERATOR
 * |  |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  |  +-- GUEST
 * |  |  |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  |  |  +-- SOURCE
 * |  |  |  |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  |  |  |  +-- GRID
 * |  |  |  |  |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  |  |  |  |  +-- PGM
 * |  |  |  |  |  |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  |  |  |  |  |  +-- MONITOR
 * |  |  |  |  |  |  |  |  |  |  |  |  |  |
 * |  |  |  |  |  |  |  |  |  |  |  |  |  |  +-- VIEWER
 * |  |  |  |  |  |  |  |  |  |  |  |  |  |  |
 * 0  0  0  0  0  0  0  0  0  0  0  0  0  0  0
 *
 */

// Object.seal(obj)

export default Object.freeze({
    ROOT: 31,
    HOSTMASTER: 30,
    EMPLOYEE: 29,
    UNDEFINED_28: 28,
    UNDEFINED_27: 27,
    UNDEFINED_26: 26,
    UNDEFINED_25: 25,
    UNDEFINED_24: 24,
    UNDEFINED_23: 23,
    UNDEFINED_22: 22,
    RESELLER: 21,
    CLIENT: 20,
    UNDEFINED_19: 19,
    AUDIO: 18,
    PGM: 17,
    GRID: 16,
    MEA: 15,
    CAMERA: 14,
    MONITOR: 13,
    UNDEFINED_12: 12,
    UNDEFINED_11: 11,
    UNDEFINED_10: 10,
    MASTER: 9,
    PUBLISHER: 8,
    MODERATOR: 7,
    JOURNALIST: 6,
    SYNTHE: 5,
    SPEAKER: 4,
    PANELIST: 3,
    GUEST: 2,
    VIEWER: 1,
    PUBLIC: 0
});
