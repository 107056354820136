<sign-up>
    <div class="container-tight py-4">
        <div class="text-center mb-2">
            <a href="." class="navbar-brand navbar-brand-autodark"><img src="assets/img/realives-logo-black.svg"
                    height="36" alt=""></a>
        </div>
        <form class="card card-md" action="." method="get" onsubmit="{createAccount}">
            <div class="card-body">
                <h2 class="card-title text-center mb-4">{__('Create new account')}</h2>
                <div class="mb-3">
                    <label class="form-label">{__('Name')}</label>
                    <input id="username" type="text" class="form-control" placeholder="{__('Enter full name')}"
                        tab-index="1" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{__('Email address')}</label>
                    <input id="email" type="email" class="form-control" placeholder="{__('Enter email')}" tab-index="2"
                        required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{__('Password')}</label>
                    <div class="input-group input-group-flat">
                        <input id="password" type="password" class="form-control" placeholder="{__('Enter a password')}"
                            autocomplete="off" tab-index="3" required>
                        <span class="input-group-text">
                            <a href="#" class="link-secondary" title="Show password" data-target="password"
                                onclick="{togglePassword}"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="2" />
                                    <path
                                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                </svg>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-check">
                        <input type="checkbox" class="form-check-input" />
                        <span class="form-check-label">{__('Agree the')} <a href="#" tabindex="-1"
                                data-bs-toggle="modal" data-bs-target="#modal-privacy-policy" required>
                                {__('terms and policy')}
                            </a>.
                        </span>
                    </label>
                </div>
                <div class="form-footer">
                    <button type="submit" class="btn btn-primary w-100">{__('Create my account')}</button>
                </div>
            </div>
        </form>
        <div class="text-center text-muted mt-3">
            {__('Already have account?')} <a href="/sign-in" tabindex="-1">{__('Sign in')}</a>
        </div>
    </div>

    <div class="modal modal-blur fade" id="modal-privacy-policy" tabindex="-1">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-status bg-info"></div>
                <div class="modal-body text-center py-4" is="tos">
                </div>
                <div class="modal-footer">
                    <div class="w-100">
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-auto">
                                <a href="#" class="btn btn-primary" data-bs-dismiss="modal">{__('close')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="modal-signup-success" class="modal" tabindex="-1">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-status bg-success"></div>
                <div class="modal-body text-center py-4">
                    <!-- Download SVG icon from http://tabler-icons.io/i/circle-check -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-green icon-lg" width="24" height="24"
                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <h3>Succedeed</h3>
                    <div class="text-muted">
                        <p>Your request have been successfully submited.</p>
                        <p>We will get in thouch with you soon.</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="w-100">
                        <div class="row">
                            <div class="col">
                                <a href="#" class="btn w-100" data-bs-dismiss="modal">
                                    Go to sign in screen
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <script>
        import { router } from '@riotjs/route';
        import axios from 'axios';

        export default {
            onBeforeMount(rops, state) {
                const config = this.store.get('config');
                axios.defaults.baseURL = config.api_uri;
            },
            onMounted(props, state) { },
            onBeforeUpdate(rops, state) { },
            onUpdated(rops, state) { },
            onBeforeUnmount(rops, state) { },
            createAccount(e) {
                e.preventDefault();
                if (this.$('#submit'))
                    this.$('#submit').disabled = true;

                const data = {
                    "username": this.$('#username').value,
                    "email": this.$('#email').value,
                    "password": this.$('#password').value
                };

                axios.post('/auth/local/register', data)
                    .then((res) => {
                        console.log(res);
                        const modalEl = this.$('#modal-signup-success');
                        const modal = bootstrap.Modal.getOrCreateInstance(modalEl);
                        if (modal) {
                            modalEl.addEventListener('hidden.bs.modal', (event) => {
                                router.push('/sign-in', 'Sign In');
                            });
                            modal.show();
                        }
                    })
                    .catch((error) => {
                        if (this.$('#submit'))
                            this.$('#submit').disabled = false;
                        if (error.response) {
                            // la requête a été faite et le code de réponse du serveur n’est pas dans
                            // la plage 2xx
                            if (error.response.data.error) {
                                this.store.trigger('error', error.response.data.error.name, error.response.data.error.status, error.response.data.error.message);
                            } else {
                                console.error(error.response.data);
                                console.error(error.response.status);
                                console.error(error.response.headers);
                            }
                        } else if (error.request) {
                            // la requête a été faite mais aucune réponse n’a été reçue
                            // `error.request` est une instance de XMLHttpRequest dans le navigateur
                            // et une instance de http.ClientRequest avec node.js
                            this.store.trigger('error', 'EmptyResponse', 500, 'No response received from the server.');
                            console.error(error.request);
                        } else {
                            // quelque chose s’est passé lors de la construction de la requête et cela
                            // a provoqué une erreur
                            this.store.trigger('error', 'UndefinedError', 500, error.message);
                            console.error('Error', error.message);
                        }
                        // console.error(error.config);
                    });

            },
            togglePassword(e) {
                e.preventDefault();
                const target = e.currentTarget.dataset.target;
                if (target) {
                    const field = this.$('#' + target);
                    if (field) {
                        field.type = (field.type === 'password') ? 'text' : 'password';
                    }
                }
            }
        }
    </script>
</sign-up>