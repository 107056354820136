<contact-us>
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-cards">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Contact Us</h3>
            </div>
            <div class="card-body py-3">
              <form action="#" onsubmit="{onSubmit}" disabled="{state.submitted}">
                <div class="row">
                  <div class="col-xl-6 col-md-8 col-12 mx-auto">

                    <div class="mb-3">
                      <label class="form-label required">Full Name</label>
                      <div class="input-icon mb-3">
                        <span class="input-icon-addon">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24"
                            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                          </svg>
                        </span>
                        <input id="sender_full_name" type="text" class="form-control" placeholder="Full Name"
                          value="{user.username}" required>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label required">E-mail</label>
                      <div class="input-icon mb-3">
                        <span class="input-icon-addon">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="24"
                            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z">
                            </path>
                            <path d="M3 7l9 6l9 -6"></path>
                          </svg>
                        </span>
                        <input id="sender_email" type="email" class="form-control" placeholder="E-mail"
                          value="{user.email}" required>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label required">Phone</label>
                      <div class="input-icon mb-3">
                        <span class="input-icon-addon">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="24"
                            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path
                              d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                            </path>
                          </svg>
                        </span>
                        <input id="sender_phone" type="phone" class="form-control" placeholder="Phone" required>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label required">Subject</label>
                      <input id="message_subject" type="text" class="form-control" placeholder="Subject" required>
                    </div>

                    <div class="mb-3">
                      <label class="form-label required">Message</label>
                      <textarea id="sender_message" class="form-control" name="example-textarea" rows="6"
                        placeholder="Your Message" required></textarea>
                    </div>

                    <div class="float-end mb-3">
                      <button type="submit" class="btn btn-primary" disabled="{state.submitted}">Submit</button>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <script>
    import axios from 'axios';

    export default {
      state: {
        percent: 0,
        loading: false,
        submitted: false,
      },
      onBeforeMount(props, state) {
        const config = this.store.get('config');
        const auth = this.store.get('auth');

        axios.defaults.baseURL = config.api_uri;

        if (auth.isLogged()) {
          this.user = auth.user;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token;
        } else {
          this.user = { username: '', email: '' };
        }
      },
      onMounted(props, state) { },
      shouldUpdate(newProps, currentProps) {
        return true;
      },
      onBeforeUpdate(props, state) { },
      onUpdated(props, state) { },
      onBeforeUnmount(props, state) { },
      onUnmounted(props, state) { },
      async onSubmit(e) {
        e.preventDefault();
        try {
          const data = { sender_full_name: '', sender_email: '', sender_phone: '', message_subject: '', sender_message: '' };
          const formData = new FormData();
          const fields = ['sender_full_name', 'sender_email', 'sender_phone', 'message_subject', 'sender_message'];

          this.update({ submitted: true })
          fields.forEach((fieldname) => {
            data[fieldname] = this.$('#' + fieldname).value;
          });

          formData.append('data', JSON.stringify(data));

          await axios.post(`/helpdesks`, formData);

          this.toaster.success('SUCCESS', 'Message Submited !');
        } catch (error) {
          this.update({ submitted: false })
          this.onError(error);
        }

      },
      onError(error) {
        // console.error('Exception Error:', error);
        this.spinner.hide();
        try {
          if (error.response) {
            if (error.response.status == 401 || error.response.status == 403) {
              this.store.trigger('logged-out');
            } else {
              const messages = [];
              if (error.response.data.error.details) {
                const errors = error.response.data.error.details.errors;
                errors.forEach((e) => { messages.push(`<li>${e.message}</li>`) });
                this.toaster.error('ERROR', `<ul>${messages.join('')}</ul>`);
              } else {
                this.toaster.error('ERROR', error.response.data.error.message);
              }
            }
          } else if (error.request) {
            this.toaster.error('Error', 'No response received from the server.');
          } else if (error.message) {
            this.toaster.error('Error', error.message);
          } else {
            this.toaster.error('Error', 'An undefined error occured while processing your request');
          }
        } catch (ex) {
          this.toaster.error('Error', 'An error occured while processing error<br>' + ex.message);
        }
      },

    }
  </script>
</contact-us>