<home-page>
    <nav-bar plugins="{plugins}" />

    <div class="page-body" is="{state.module}" url="{state.url}">
    </div>


    <script>
        import { route, router, toRegexp, match } from '@riotjs/route';
        // import { mount, unmount, register, unregister, component } from 'riot';
        import lazy from '@riotjs/lazy';
        import RolesMap from '../models/RolesMap';
        import axios from 'axios';

        import clientPlugins from '../client-plugins.js';
        import employeePlugins from '../employee-plugins.js';

        export default {
            components: {
                'dashboard': lazy('loader', () => import('#modules/dashboard/plugin.riot')),
                'streaming-plugin': lazy('loader', () => import('#modules/streaming/plugin.riot')),
                'vod-plugin': lazy('loader', () => import('#modules/vod/plugin.riot')),
                'srt-plugin': lazy('loader', () => import('#modules/srt/plugin.riot')),
                'webinar-plugin': lazy('loader', () => import('#modules/webinar/plugin.riot')),
                'simulator-plugin': lazy('loader', () => import('#modules/simulator/plugin.riot')),
                'diagnostic-plugin': lazy('loader', () => import('#modules/diagnostic/plugin.riot')),
            },
            onBeforeMount(props, state) {
                const Config = this.store.get('config');
                const auth = this.store.get('auth');
                this.plugins = [];
                this.current = '';
                this.user = auth.user || {};

                if (this.user && this.user.role) {
                    let role = RolesMap.get(this.user.role);

                    // console.log('Home page Role:', role);

                    if (role.toLowerCase() == 'client') {
                        this.plugins = clientPlugins;
                    } else if (role.toLowerCase() == 'employee') {
                        this.plugins = employeePlugins;
                    }

                    if (this.plugins.length) {
                        axios.defaults.baseURL = Config.api_uri;
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token;

                        this.plugins.forEach(plugin => {
                            route(plugin.path).on.value(this.onRoute);
                        });
                        route('/').on.value(_ => this.update({ url: window.location, module: 'dashboard' }));

                        if (window.location.pathname == '' || window.location.pathname == '/') {
                            this.current = 'dashboard';
                            this.state = { url: window.location, module: 'dashboard' };
                        } else {
                            // fill state with current plugin data
                            const plugin = this.plugins.find(p => window.location.pathname.startsWith(p.path));
                            // const plugin = this.plugins.find(p => match(window.location.pathname, toRegexp(p.path)));
                            this.current = plugin.target;
                            this.state = { url: window.location, module: plugin.target };
                        }
                        return;
                    }
                }
                this.state = { url: window.location, module: 'error-404' };
            },
            onMounted(props, state) {
                // this.loadNews()
                // this.loadBugReport()
            },
            onBeforeUnmount() {
                // this.route.end()
            },
            onRoute(url) {
                const plugin = this.plugins.find(p => match(window.location.pathname, toRegexp(p.path)));

                this.update({ url: url, module: plugin.target });
            },
        }
    </script>
</home-page>