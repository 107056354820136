import iziToast from 'izitoast';

iziToast.settings({
    maxWidth: 340,
    balloon: true,
    position: 'center',
    overlay: true,
    layout: 3,
    displayMode: 'once',
    titleColor: 'rgb(255, 255, 255)',
    messageColor: 'rgb(255, 255, 255)',
    iconColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(255, 255, 255)',
    drag: false,
    close: false,
    closeOnEscape: false,
    closeOnClick: false,
    timeout: null,
});

export default class Toaster {

    static halt(title, message) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.error({
            balloon: false,
            progressBarColor: 'rgb(255, 0, 0)',
            close: false,
            closeOnEscape: false,
            closeOnClick: false,
            timeout: null,
            title: title,
            message: message
        });
    }

    static yesno(title, message, onConfirm, onDecline = null, defaultToTrue = false) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.warning({
            balloon: false,
            progressBarColor: 'rgb(255, 130, 0)',
            close: false,
            closeOnEscape: false,
            closeOnClick: false,
            timeout: null,
            title: title,
            message: message,
            position: 'center',
            buttons: [
                ['<button><b>OUI</b></button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOut'
                    }, toast, 'yes');
                }, defaultToTrue],
                ['<button>NON</button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOut'
                    }, toast, 'no');
                }, !defaultToTrue],
            ],
            onClosing: function (instance, toast, closedBy) {
                // console.info('Closing | closedBy: ' + closedBy);
            },
            onClosed: function (instance, toast, closedBy) {
                if (closedBy == 'yes') {
                    onConfirm();
                } else {
                    if (onDecline && typeof onDecline === 'function') onDecline();
                }
            }
        });
    }

    static fatal(title, message) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.error({
            balloon: false,
            progressBarColor: 'rgb(255, 0, 0)',
            close: false,
            closeOnEscape: false,
            closeOnClick: false,
            timeout: null,
            title: title,
            message: message,
            buttons: [
                ['<button><b>OK</b></button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOut'
                    }, toast, 'yes');
                }, true]
            ],
            onClosed: function (instance, toast, closedBy) {
                window.location.reload();
            }
        });
    }

    static error(title, message) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.error({
            balloon: false,
            titleColor: 'rgb(255, 255, 255)',
            messageColor: 'rgb(255, 255, 255)',
            iconColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgb(200, 0, 0)',
            progressBar: false,
            close: false,
            closeOnEscape: false,
            closeOnClick: false,
            timeout: null,
            title: title,
            message: message,
            buttons: [
                ['<button><b>OK</b></button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOut'
                    }, toast, 'yes');
                }, true]
            ],
            onClosed: function (instance, toast, closedBy) {
                // window.location.reload();
            }
        });
    }

    static failed(title, message, callback = null) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.warning({
            balloon: false,
            backgroundColor: 'rgb(255, 200, 200)',
            progressBarColor: 'rgb(255, 0, 0)',
            close: true,
            closeOnEscape: true,
            closeOnClick: true,
            timeout: 1500,
            title: title,
            message: message,
            onClosed: (instance, toast, closedBy) => {
                if (callback && typeof callBack === 'function') {
                    callback(instance, toast, closedBy);
                }
            }
        });
    }

    static warning(title, message, callback = null) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.warning({
            balloon: false,
            backgroundColor: 'rgb(255, 200, 160)',
            progressBarColor: 'rgb(255, 130, 0)',
            close: true,
            closeOnEscape: true,
            closeOnClick: true,
            timeout: 1500,
            title: title,
            message: message,
            onClosed: (instance, toast, closedBy) => {
                if (callback && typeof callBack === 'function') {
                    callback(instance, toast, closedBy);
                }
            }
        });
    }

    static info(title, message, callback = null) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.info({
            position: 'topRight',
            overlay: false,
            balloon: false,
            titleColor: 'rgb(255, 255, 255)',
            messageColor: 'rgb(255, 255, 255)',
            iconColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgb(0, 80, 255)',
            progressBarColor: 'rgb(255, 255, 255)',
            progressBar: false,
            close: true,
            closeOnEscape: true,
            closeOnClick: true,
            timeout: 1500,
            title: title,
            message: message,
            onClosed: (instance, toast, closedBy) => {
                if (callback && typeof callBack === 'function') {
                    callback(instance, toast, closedBy);
                }
            }
        });
    }

    static success(title, message, callback = null) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.success({
            position: 'topRight',
            overlay: false,
            balloon: false,
            titleColor: 'rgb(255, 255, 255)',
            messageColor: 'rgb(255, 255, 255)',
            iconColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgb(0,190,0)',
            progressBarColor: 'rgb(255, 255, 255)',
            close: true,
            closeOnEscape: true,
            closeOnClick: true,
            timeout: 2500,
            title: title,
            message: message,
            onClosed: (instance, toast, closedBy) => {
                if (callback && typeof callBack === 'function') {
                    callback(instance, toast, closedBy);
                }
            }
        });
    }

    static message(title, message, callback = null) {
        if (!message) {
            message = title;
            title = '';
        }
        iziToast.info({
            balloon: true,
            titleColor: 'rgb(255, 255, 255)',
            messageColor: 'rgb(255, 255, 255)',
            iconColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgb(0, 80, 255)',
            progressBarColor: 'rgb(255, 255, 255)',
            // progressBarColor: 'rgb(0, 80, 255)',
            close: false,
            closeOnEscape: false,
            closeOnClick: false,
            timeout: null,
            title: title,
            message: message,
            buttons: [
                ['<button><b>OK</b></button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOut'
                    }, toast, 'yes');
                }, true]
            ],
            onClosed: function (instance, toast, closedBy) {
                if (callback && typeof callBack === 'function') {
                    callback();
                }
            }
        });
    }
}