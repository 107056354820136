<image-upload>
    <div class="image-upload-wrapper">
        <div class="image-upload-overlay">
            <p class="image-upload-label">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud-upload" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1">
                    </path>
                    <path d="M9 15l3 -3l3 3"></path>
                    <path d="M12 12l0 9"></path>
                </svg>
                <span class="ms-2" if="{state.label}">{state.label}</span>
            </p>
            <input type="file" id="{state.id}" class="image-upload-input" required="{state.required}"
                accept="{state.accept}" oninput="{preview}">
        </div>
    </div>
    <script>
        /**
         * usage:
         * <image-upload input-id="file1" label="Upload an image" background-size="cover" accept="image/*" required="true" />
         * or
         * <div is="image-upload" input-id="file2" label="Upload a pic" background-size="cover" accept="image/*" required="true"></div>
         **/
        import './image-upload.css';

        export default {
            onBeforeMount(props, state) {
                this.imgURL = null;
                this.backgroundSize = 'contain';
                this.backgroundTarget = props.backgroundTarget || '.image-upload-wrapper';
                this.maxFileSize = props.maxFileSize || 1 * 1024 * 1024;

                this.state = {
                    id: props.fieldId || 'field-' + this.randomNumber(100, 999),
                    label: props.label,
                    accept: props.accept || 'image/*',
                    required: props.required,
                };
            },
            onMounted(props, state) {
                if (props.default) {
                    this.setDefaultImage(props.default);
                }
            },
            shouldUpdate(newProps, currentProps) {
                if (newProps.backgroundSize !== currentProps.backgroundSize) {
                    this.setBackgroundSize(newProps.backgroundSize);
                }
                if (newProps.default !== currentProps.default) {
                    this.setDefaultImage(newProps.default);
                }
                return false;
            },
            onBeforeUpdate(props, state) { },
            onUpdated(props, state) { },
            onBeforeUnmount(props, state) { },
            preview(e) {
                // don't use this.$() here because element can be outside of scope
                const elID = e.currentTarget.id;
                const file = e.currentTarget.files[0];

                if (file.size > this.maxFileSize) {
                    // e.preventDefault();
                    e.currentTarget.setCustomValidity('This file size is bigger than the max allowed size ! Max allowed file size is ' + this.getHumaReadableSize(this.maxFileSize));
                    e.currentTarget.reportValidity();
                    return;
                } else {
                    e.currentTarget.setCustomValidity('');
                }

                if (this.props.onFileSelected && typeof this.props.onFileSelected == 'function') {
                    this.props.onFileSelected(elID, file);
                } else {
                    // console.log('Type of onFileSelected:' + typeof this.props.onFileSelected);
                }

                if (this.imgURL)
                    window.URL.revokeObjectURL(this.imgURL);

                if (['none', 'disabled'].indexOf(this.backgroundTarget) == -1) {
                    let el;
                    if (this.backgroundTarget == '.image-upload-wrapper') {
                        el = this.$(this.backgroundTarget);
                    } else {
                        el = document.querySelector(this.backgroundTarget);
                    }
                    this.imgURL = window.URL.createObjectURL(file);
                    if (el) {
                        el.style.setProperty('background-image', 'url(' + this.imgURL + ')');
                    }
                }
                const btn = this.$('.image-upload-label');
                if (btn)
                    btn.style.setProperty('display', 'none');
            },
            setDefaultImage(url) {
                if (url && ['none', 'disabled'].indexOf(this.backgroundTarget) == -1) {
                    let el;
                    if (this.backgroundTarget == '.image-upload-wrapper') {
                        el = this.$(this.backgroundTarget);
                    } else {
                        el = document.querySelector(this.backgroundTarget);
                    }
                    if (el && url) {
                        el.style.setProperty('background-image', 'url(' + url + ')');
                    }

                    const btn = this.$('.image-upload-label');
                    if (btn)
                        btn.style.setProperty('display', 'none');
                }
            },
            setBackgroundSize(size) {
                const el = document.querySelector(this.backgroundTarget);
                if (el)
                    el.style.setProperty('background-size', size);
            },
            randomNumber(min, max) {
                return Math.floor(Math.random() * (1 + max - min) + min);
            },
            getHumaReadableSize(bytes, dm = 2) {
                if (bytes == 0) return '0 Bytes';
                var k = 1024,
                    sizes = [`${bytes <= 1 ? "Byte" : "Bytes"}`, 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
        }
    </script>
</image-upload>