<front-end>
    <!-- <router initial-route="/#" base="#">-->
    <div if={isLogged && currentPage.menu} class="wrapper">
        <header-bar pages="{state.pages}" active="{currentPage}" />
    </div>
    <!--
        <div if={state.showNotFound} class="page page-center">
            <div is="error-404" class="e404"></div>
        </div>
        <route each={page in state.pages} path="{page.path}" if={!state.showNotFound}>
            <div id="current-content" params={route? route.params:null} class="{page.wrapped? 'page-wrapper' : '' }"
                is={page.componentName}></div>
        </route>
        -->

    <div id="current-content" class="{currentPage.wrapped? 'page-wrapper' : '' }" is="{currentPage.componentName}">
    </div>

    <!-- <footer if={state.showMenu} is="page-footer" class="footer footer-transparent d-print-none" /> -->
    <!-- </router>-->

    <div class="modal modal-blur fade" id="modal-report-error" tabindex="-1">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-status bg-danger"></div>
                <div class="modal-body text-center py-4">
                    <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24"
                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 9v2m0 4v.01" />
                        <path
                            d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
                    </svg>
                    <h3>{state.error.name} ({state.error.status})</h3>
                    <div class="text-muted">{state.error.message}</div>
                </div>
                <div class="modal-footer">
                    <div class="w-100">
                        <div class="row">
                            <div class="col">
                                <a href="#" class="btn w-100" data-bs-dismiss="modal">OK</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        import { mount, unmount } from 'riot';
        import { route, router, toRegexp, match, setBase } from '@riotjs/route';
        import lazy from '@riotjs/lazy';
        import axios from 'axios';
        import Config from './config/config';
        import splash from '#lib/SplashScreen';
        import Auth from '#lib/Auth';
        import RolesMap from './models/RolesMap';
        import pages from './pages';

        import './front-end.css';

        export default {
            components: {
                'bug-report': lazy('loader', () => import('#modules/bug-report/bug-report.riot')),
                'user-profile': lazy('loader', () => import('#modules/user/user-profile.riot')),
            },

            state: {
                userid: 0, // id
                username: '', // username
                useremail: '', // email
                usertype: '', // userRole
                avatar: '',
                pages,
                showMenu: false,
                activePage: null,
                redirectPage: null,
                error: { name: '', status: 200, message: '' }
            },
            onBeforeMount() {
                const self = this;
                this.auth = new Auth(Config.api_uri, false);
                this.auth.on('logged-out', this.onLogOut);
                this.isLogged = this.auth.isLogged();
                // console.log('LOADING APP...', this.auth);

                splash.hide();

                this.store.set('config', Config);
                this.store.set('auth', this.auth);

                // console.log('Pages: ', pages);
                pages.forEach(page => {
                    // console.log('Page', page);
                    route(page.path).on.value(this.onRoute);
                });
                // this.store.on('logged-out', this.onLogOut);
                this.store.on('error', this.onError);

                // router.push('/', 'Home');
                this.currentPage = null;
                this.getPage();

                mount('#footer', {}, 'page-footer');
                // this.route.end();
            },
            onMount(props, state) {
                // this.spinner.hide();
            },
            onBeforeUpdate(props, state) {
                // console.log(state);
                /*
                this.isLogged = this.auth.isLogged();
                if (this.isLogged && !this.currentPage) {
                    this.currentPage = pages.find(p => p.path == '/');
                }
                */
            },
            getPage(url) {
                console.log('front-end::getPage [url]', url);
                let activePage;
                this.isLogged = this.auth.isLogged();
                console.log('front-end::getPage [isLogged]', this.isLogged);

                if (!url) url = window.location;
                console.log('front-end::getPage [url (2)]', url);

                if (url.pathname == '/' || url.pathname == '') {
                    this.currentPage = pages.find(p => p.path == '/');
                } else {
                    this.currentPage = pages.find(p => match(url.pathname, toRegexp(p.path))); // for base='/''
                    // activePage = pages.find(p => match(url.hash, toRegexp(p.path.slice(1)))); // for base='#'

                    if (!this.currentPage) {
                        // page not found; load home
                        this.currentPage = pages.find(p => p.path == '/');
                    }
                }
                console.log('front-end::getPage [currentPage]', this.currentPage);

                if (!this.isLogged && this.currentPage.auth) {
                    router.push('/sign-in', 'Sign In');
                }

                if (this.isLogged) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.auth.token;
                }
                // console.log('currentPage', this.currentPage);
            },
            onRoute(url) {
                this.getPage(url);
                this.update();
            },
            onBeforeUnmount() {
                // this.route.end()
            },
            onLogOut() {
                router.push('/sign-in', 'Sign In');
            },
            displayError(code, status, message) {
                this.spinner.hide();
                const modalEl = document.getElementById('modal-report-error');
                if (modalEl) {
                    const modal = bootstrap.Modal.getOrCreateInstance(modalEl);
                    if (modal) {
                        this.update({ error: { name: code, status: status, message: message } });
                        if (status == 401 || status == 403)
                            modalEl.addEventListener('hidden.bs.modal', (event) => {
                                router.push('/sign-in', 'Sign In');
                            });
                        modal.show();
                    }
                }
            },
            onError(error) {
                // console.error('Exception Error:', error);
                try {
                    this.spinner.hide();
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 403) {
                            this.store.trigger('logged-out');
                        } else {
                            if (error.response.data.error.details) {
                                const messages = [];
                                const errors = error.response.data.error.details.errors;
                                errors.forEach((e) => { messages.push(`<li>${e.message}</li>`) });
                                this.toaster.error('ERROR', `<ul>${messages.join('')}</ul>`);
                            } else {
                                this.toaster.error('ERROR', error.response.data.error.message);
                            }
                        }
                    } else if (error.request) {
                        this.toaster.error('Error', 'No response received from the server.');
                    } else if (error.message) {
                        this.toaster.error('Error', error.message);
                    } else if (error.constructor === String) {
                        this.toaster.error('Error', error);
                    } else {
                        this.toaster.error('Error', 'An undefined error occured while processing your request');
                    }
                } catch (ex) {
                    this.toaster.error('Error', 'An error occured while processing error<br>' + ex.message);
                }
            },
        }
    </script>
</front-end>