<emoji-upload>
    <div class="emoji-container">
        <a href="#" class="btn btn-link" data-rl-emoji="{state.emojiID}" onclick="{state.onEmojiClicked}">
            <img src="{state.emoji}" alt="{state.emoji}" if="{isImage(state.emoji)}">
            <span if="{!isImage(state.emoji)}">{state.emoji}</span>
        </a>
    </div>
    <div class="emoji-upload-container">
        <label for="" class="btn btn-{state.color} btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud-upload" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                <path d="M9 15l3 -3l3 3"></path>
                <path d="M12 12l0 9"></path>
            </svg>
            <input id="{state.id}" type="file" class="emoji-upload-input" required="{state.required}" accept="'image/*'"
                oninput="{preview}">
        </label>
        <button type="button" class="btn btn-danger btn-icon ms-1" aria-label="Clear emoji" aria-hidden="true"
            data-bs-toggle="tooltip" data-rl-emoji="{state.emojiID}" onclick="{cleanEmoji}">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24"
                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
        </button>
    </div>

    <script>
        /**
         * usage:
         * <image-upload input-id="file1" label="Upload an image" background-size="cover" accept="image/*" required="true" />
         * or
         * <div is="image-upload" input-id="file2" label="Upload a pic" background-size="cover" accept="image/*" required="true"></div>
         **/
        import './emoji-upload.scss';

        export default {
            state: {
                id: 'field-0',
                color: 'purple',
                emoji: '😃',
                emojiID: 0,
                required: false,
                onEmojiClicked: null,
                onEmojiChanged: null,
                onEmojiClean: null,
            },
            onBeforeMount(props, state) {
                this.maxFileSize = props.maxFileSize || 0.1 * 1024 * 1024;
                this.internalChange = false;
                this.imgURL = null;

                this.state = {
                    id: props.fieldId || 'field-' + this.randomNumber(100, 999),
                    color: props.color || 'purple',
                    emoji: props.emoji || '',
                    emojiID: props.emojiId || 0,
                    required: props.required || false,
                    onEmojiClicked: props.emojiClick || this.noop,
                    onEmojiChanged: props.emojiChanged || this.noop,
                    onEmojiClean: props.emojiClean || this.noop,
                };
            },
            onMounted(props, state) {
            },
            onBeforeUpdate(props, state) {
                if (!this.internalChange) {
                    this.state = {
                        id: props.fieldId || 'field-' + this.randomNumber(100, 999),
                        color: props.color || 'purple',
                        emoji: props.emoji || '',
                        emojiID: props.emojiId || 0,
                        required: props.required || false,
                        onEmojiChanged: props.emojiChanged || this.noop,
                        onEmojiClicked: props.emojiClick || this.noop,
                        onEmojiClean: props.emojiClean || this.noop,
                    };
                    this.internalChange = false;
                }
            },
            onUpdated(props, state) { },
            onBeforeUnmount(props, state) { },
            cleanEmoji(e) {
                this.$('#' + this.state.id).value = null;
                if (this.state.onEmojiClean && typeof this.state.onEmojiClean == 'function') {
                    this.state.onEmojiClean({ id: this.state.emojiID, field: this.state.id });
                }
            },
            preview(e) {
                // don't use this.$() here because element can be outside of scope
                const elID = e.currentTarget.id;
                const file = e.currentTarget.files[0];
                const emo = e.currentTarget.files[0];

                if (file.size > this.maxFileSize) {
                    // e.preventDefault();
                    e.currentTarget.setCustomValidity('This file size is bigger than the max allowed size ! Max allowed file size is ' + this.getHumaReadableSize(this.maxFileSize));
                    e.currentTarget.reportValidity();
                    return;
                } else {
                    e.currentTarget.setCustomValidity('');
                }

                if (this.props.onFileSelected && typeof this.props.onFileSelected == 'function') {
                    this.props.onFileSelected(elID, file);
                } else {
                    // console.log('Type of onFileSelected:' + typeof this.props.onFileSelected);
                }

                if (this.imgURL)
                    window.URL.revokeObjectURL(this.imgURL);

                this.imgURL = window.URL.createObjectURL(file);
                this.internalChange = true;
                this.update({ emoji: this.imgURL });
                if (this.state.onEmojiChanged && typeof this.state.onEmojiChanged == 'function') {
                    this.state.onEmojiChanged({ id: this.state.emojiID, field: this.state.id, emoji: this.imgURL, file: file });
                }
            },
            randomNumber(min, max) {
                return Math.floor(Math.random() * (1 + max - min) + min);
            },
            isImage(emoji) {
                if (['blob', 'http'].indexOf(emoji.substring(0, 4)) === -1) {
                    return false;
                }
                return true;
                // return emoji.substring(0, 8) === 'https://';
            },
            getHumaReadableSize(bytes, dm = 2) {
                if (bytes == 0) return '0 Bytes';
                var k = 1024,
                    sizes = [`${bytes <= 1 ? "Byte" : "Bytes"}`, 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            noop(e) {
                if (e)
                    try {
                        e.preventDefault();
                    } catch (error) {

                    }
                return false;
            }
        }
    </script>
</emoji-upload>