/**
 *
 *   WARNING DO NOT UPDATE CONFIG.JS DIRECTLY
 *   INSTEAD UPDATE DEV.CONFIG.JS AND PROD.CONFIG.JS
 *
 */

export default {
    "api_uri": "https://apiv2.realives.com/api",
    "wss_uri": "https://wip.realives.com",
    "analytics_url": "https://analytics.realives.com",
    "snap_uri": "https://snap.dev.realives.com/{{stream-type}}/{{stream-id}}?u={{user-name}}",
    "snap_iframe": "https://snap.dev.realives.com/{{stream-type}}/embeded/{{stream-id}}?u=_FULL-NAME_",
    "assets_base": "https://apiv2.realives.com",
    "default_logo": "https://apiv2.realives.com/uploads/realives_logo_black.png",
    "default_poster": "https://apiv2.realives.com/uploads/no_signal.jpg",
    "default_fallback": "https://apiv2.realives.com/uploads/no_signal.jpg",
    "pdf_templates": "/assets/pdf-templates",

    "streaming": {
        "assets_url": "https://apiv2.realives.com",
        "video_url": "https://apiv2.realives.com",
        "max_video_weight": 10 * (1024 * 1024 * 1024), // 10 GB
        "max_logo_weight": 200 * 1024, // 200KB
        "max_picture_weight": 2 * (1024 * 1024), // 2MB
    },
    "vod": {
        "http_uri": "https://snap.dev.realives.com/{{stream-type}}/{{stream-id}}?u={{user-name}}",
        "iframe_uri": "https://snap.dev.realives.com/{{stream-type}}/embeded/{{stream-id}}?u=_FULL-NAME_",
        "assets_url": "https://apiv2.realives.com",
        "video_url": "https://apiv2.realives.com",
        "max_video_weight": 10 * (1024 * 1024 * 1024), // 10 GB
        "max_logo_weight": 200 * 1024, // 200KB
        "max_picture_weight": 2 * (1024 * 1024), // 2MB
    },
    "srt": {
        "server": "srt.realives.com",
        "port": 9560,
        "publish_url": "srt://{{server}}:{{port}}?streamid=publish/live/{{stream-key}}",
        "play_url": "srt://{{server}}:{{port}}?streamid=play/live/{{stream-key}}",
        "audio_url": "https://audio.realives.com/{{token}}",
    },
    "webinar": {
        "public_uri": "https://live.realives.com/{{webinar-key}}/{{key}}",
        "prod_uri": "https://prod2.realives.com/{{webinar-key}}",
        "assets_url": "https://apiv2.realives.com",
        "janode_uri": "https://wip.realives.com",
        "janode_path": "/webrtc/",
        "whip_server": "live.realives.com",
    },
    "interaction": {
        "uri": "https://interaction.realives.com/{{plugin}}/{{stream-id}}",
        "wss_uri": "https://interaction.realives.com",
        /* "wss_path": "/interact/", */
    },
    "simulator": {
        "wss_uri": "http://127.0.0.1:3333"
    },
    "diagnostic": {
        "public_url": "https://diagnostic.realives.com",
        "wss_uri": "https://diagnostic.realives.com"
    },

    "max_video_weight": 10 * (1024 * 1024 * 1024), // 10 GB
    "max_logo_weight": 200 * 1024, // 200KB
    "max_picture_weight": 2 * (1024 * 1024), // 2MB
}
