<copy-to-clipboard>
    <div class="clipboard" tabindex="-1" role="button" onclick="{copyToClipboard}">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2">
            </path>
            <rect x="9" y="3" width="6" height="4" rx="2">
            </rect>
        </svg>
        <span class="clipboardtext" hidden>{__('Copied')}!</span>
    </div>
    <script>
        import './copy-to-clipboard.scss';

        export default {
            onBeforeMount(props, state) {
                if (!props.source) {
                    throw new Error('Parameter source is missing');
                }
                this.targetElID = props.source;
            },
            copyToClipboard(e) {
                e.preventDefault();

                const self = this;
                try {
                    let text = document.getElementById(this.targetElID).dataset.copy || document.getElementById(this.targetElID).innerText;
                    navigator.clipboard
                        // .writeText(document.getElementById(this.targetElID).innerText)
                        .writeText(text)
                        .then(
                            (success) => {
                                const notice = self.$('.clipboardtext');
                                notice.hidden = false;
                                setTimeout(function () {
                                    notice.hidden = true;
                                }, 1500)
                            },
                            (err) => console.log("error copying text")
                        );
                } catch (err) {
                    console.error("Error occured while copying text:", err);
                }
            }
        }
    </script>
</copy-to-clipboard>