<nav-bar>
    <div class="navbar-expand-md">
        <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="navbar navbar-light">
                <div class="container-xl">
                    <ul class="navbar-nav">
                        <li class="nav-item active">
                            <a class="nav-link" href="/" data-page="/">
                                <span class="nav-link-icon d-md-none d-lg-inline-block" is="rl-icon-home">
                                </span>
                                <span class="nav-link-title">
                                    {__('Home')}
                                </span>
                            </a>
                        </li>

                        <li each={ plugin in plugins } class="nav-item" if="{user.packs[plugin.name]}">
                            <a class="nav-link" href="{plugin.path}">
                                <span class="nav-link-icon d-md-none d-lg-inline-block" is="rl-icon-{plugin.icon}">
                                </span>
                                <span class="nav-link-title">{__(plugin.label)}</span>
                            </a>
                        </li>
                        <!--
                        <li class="nav-item">
                            <a class="nav-link" href="/simulator">
                                <span class="nav-link-icon d-md-none d-lg-inline-block" is="rl-icon-simulator">
                                </span>
                                <span class="nav-link-title">{__('Simulator')}</span>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="/diagnostic">
                                <span class="nav-link-icon d-md-none d-lg-inline-block" is="rl-icon-diagnostic">
                                </span>
                                <span class="nav-link-title">{__('Diagnostics')}</span>
                            </a>
                        </li>
                        -->
                    </ul>
                </div>
            </div>
        </div>
        <!--
        <div class="container-xl">
            <ol class="breadcrumb" aria-label="breadcrumbs">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Library</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="#">Data</a></li>
            </ol>
        </div>
        -->
    </div>

    <script>

        export default {
            onBeforeMount(props, state) {
                this.auth = this.store.get('auth');
                this.user = this.auth.user;

                this.plugins = props.plugins;
            },
            onMounted(props, state) {
                const self = this;
                const path = window.location.pathname;
                const links = this.$$('ul.navbar-nav>li.nav-item>a.nav-link');
                links.forEach(element => {
                    element.addEventListener('click', (event) => {
                        links.forEach(el => { el.parentElement.classList.toggle('active', false); });
                        event.currentTarget.parentElement.classList.toggle('active', true);
                    });
                });
                // console.log('nav-bar Location', path);
                if (['', '/'].indexOf(path) == -1) {
                    links.forEach(el => {
                        const url = new URL(el.href);
                        // console.log('nav-bar url', url);
                        if (['', '/'].indexOf(url.pathname) == -1) {
                            el.parentElement.classList.toggle('active', path.startsWith(url.pathname));
                        } else {
                            el.parentElement.classList.toggle('active', false);
                        }
                    });
                }
                this.createBreadcrumb();
            },
            createBreadcrumb() {
                const here = location.href.replace(/(\?.*)$/, '').split('/').slice(3);
                const parts = [
                    { text: 'Home', link: '/', current: false }
                ];

                for (let j = 0; j < here.length; j++) {
                    let part = here[j];
                    let pageName = part.toLowerCase();
                    pageName = part.charAt(0).toUpperCase() + part.slice(1);
                    let link = '/' + here.slice(0, j + 1).join('/');
                    // $('#siteBreadcrumb ol.breadcrumb').append('<li><a href="' + link + '">' + pageName.replace(/\.(htm[l]?|asp[x]?|php|jsp)$/, '') + '</a></li>');
                    parts.push({ text: pageName, link: link, current: (j + 1 == here.length) });
                }

                // console.log('Breadcrumb:', parts);
            },
            generateBreadCrumb(pathname) {
                var paths = pathname.split("/");

                // remove the last element if there was a / at the end of the pathname
                paths = paths[paths.length - 1] === "" ? paths.slice(0, paths.length - 1) : paths;

                // remove the first element if the second one is an empty string which means that we are in the root of the website
                paths = paths[1] === "" ? paths.slice(1) : paths;

                var breadcrumb = paths.map((path, index) => {
                    // Add the > symbol only between two links
                    var arrow = index !== paths.length - 1 ? " > " : " ";

                    // The first element should receive the <IndexLink> React element
                    if (index === 0) {
                        return '<li><IndexLink key={index} to="/" activeClassName="active">Home</IndexLink>{arrow}</li>';
                    }

                    // Build the path for the current URL
                    var url = '/' + paths.slice(0, index + 1).join('/');

                    // HTML structure for every link except the first
                    return '<li><Link key={index} to={url}>{path}</Link>{arrow}</li>';
                });

                // Return a list of links
                return '<ul className="inline-list">{breadcrumb}</ul>';
            },
            render() {
                // When rendering the component, calculate the HTML structure of the breadcrumb
                var breadcrumb = this.generateBreadCrumb(window.location.pathname);
                return ('<div>{breadcrumb}</div>');
            }
        }
    </script>
</nav-bar>