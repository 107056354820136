<formated-html>
    <script>
        import '../../../lib/StringUtils';
        export default {
            setInnerHTML(html) {
                this.root.innerHTML = String(html).nl2br().linkify();
            },
            onMounted(props, state) {
                this.setInnerHTML(props.html);
            },
            onUpdated(props, state) {
                this.setInnerHTML(props.html);
            }
        }
    </script>
</formated-html>