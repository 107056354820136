if (!String.ucFirst) {
    String.prototype.ucFirst = function () {
        var str = this.valueOf();
        return this[0].toUpperCase() + this.slice(1);
    }
}

if (!String.linkify) {
    String.prototype.linkify = function () {

        // http://, https://, ftp://
        var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

        // www. sans http:// or https://
        var pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

        // Email addresses *** here I've changed the expression ***
        var emailAddressPattern = /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;

        return this
            .replace(urlPattern, '<a target="_blank" href="$&">$&</a>')
            .replace(pseudoUrlPattern, '$1<a target="_blank" href="http://$2">$2</a>')
            .replace(emailAddressPattern, '<a target="_blank" href="mailto:$1">$1</a>');
    };
}

if (!String.prototype.trime) {
    String.prototype.trime = function () {
        return this.replace(/^[\s\uFEFF\xA0#]+|[\s\uFEFF\xA0#]+$/g, '');
    };
}

if (!String.prototype.nl2br) {
    String.prototype.nl2br = function () {
        return this.replaceAll('\n', '<br>');
    };
}

if (!String.prototype.format) {
    String.prototype.format = function () {
        return [...arguments].reduce((p, c) => p.replace(/%s/, c), this);
    };
}

if (!String.prototype.base64Encode) {
    String.prototype.base64Encode = function () {
        // const bytes = this.valueOf();
        // const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
        // return btoa(binString);
        const buffer = Buffer.from(this.valueOf(), 'utf-8');
        return buffer.toString('base64');
    };
}

/*
if (!String.prototype.wrap) {
    String.prototype.wrap = function (maxlen = 72) {
        let _maxlength = maxlen - 4;
        return this.length > maxlen ? this.slice(0, _maxlength) + '...' : this;
    }
}
*/

if (!String.prototype.reduce) {
    String.prototype.reduce = function (maxlen = 72) {
        let _maxlength = maxlen - 4;
        let _begin = Math.floor(_maxlength / 2);
        let _end = 0 - Math.floor(_maxlength / 2);
        return this.length > maxlen ? this.slice(0, _begin) + '...' + this.slice(_end) : this;
    }
}

if (!String.prototype.toVarName) {
    String.prototype.toVarName = function () {
        return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
            .replace(/([^\w_]+|\s+)/g, '-') // Replace space and other characters by hyphen
            .replace(/\-\-+/g, '-')	// Replaces multiple hyphens by one hyphen
            .replace(/(^-+|-+$)/g, ''); // Remove extra hyphens from beginning or end of the string
    };
}

window.fixWindowSize = function () {
    const root = document.querySelector(':root');
    root.style.setProperty('--full-height', window.innerHeight + 'px');
    console.log('Updating window size ...');
}
