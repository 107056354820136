
export default [
    { name: 'streaming', label: 'Streaming', icon: 'broadcast', path: '/stream', target: 'streaming-plugin' },
    { name: 'vod', label: 'VOD', icon: 'vod', path: '/vod', target: 'vod-plugin' },
    { name: 'srt', label: 'SRT', icon: 'broadcast', path: '/srt', target: 'srt-plugin' },
    { name: 'webinar', label: 'Webinars', icon: 'network', path: '/webinar', target: 'webinar-plugin' },
    { name: 'interactivity', label: 'Interactivité', icon: 'game', path: '/interactivity', target: 'interactivity-plugin' },
    { name: 'whip', label: 'Multi-site', icon: 'broadcast', path: '/multi-site', target: 'multi-site' },
    { name: 'simulator', label: 'Simulator', icon: 'simulator', path: '/simulator', target: 'simulator-plugin' },
    { name: 'diagnostic', label: 'Diagnostics', icon: 'diagnostic', path: '/diagnostic', target: 'diagnostic-plugin' }
];