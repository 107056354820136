<sign-in>
    <div class="container-tight vertical-center py-4">
        <div class="text-center mb-2">
            <a href="." class="navbar-brand navbar-brand-autodark"><img src="assets/img/realives-logo-black.svg"
                    height="36" alt=""></a>
        </div>
        <form class="card card-md" autocomplete="off" onsubmit="{doLogin}">
            <div class="card-body">
                <h2 class="card-title text-center mb-4">{__('Login to your account')}</h2>
                <div class="mb-3">
                    <label for="identifier" class="form-label">{__('Email address')}</label>
                    <input id="identifier" type="email" class="form-control" placeholder="{__('Enter email')}"
                        tabindex="1" required>
                </div>
                <div class="mb-2">
                    <label for="password" class="form-label">{__('Password')}</label>
                    <div class="input-group input-group-flat">
                        <input id="password" type="password" class="form-control" placeholder="{__('Enter a password')}"
                            tabindex="2" autocomplete="off" required>
                        <span class="input-group-text">
                            <a href="#" class="link-secondary" title="Show password" data-target="password"
                                onclick="{togglePassword}">
                                <!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="2" />
                                    <path
                                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                </svg>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="form-check">
                        <input id="remember-me" type="checkbox" class="form-check-input" tabindex="3" />
                        <span class="form-check-label">{__('Remember me on this device')}</span>
                    </label>
                </div>
                <div class="form-footer">
                    <button id="submit" type="submit" class="btn btn-primary w-100" tabindex="4">
                        {__('Sign in',{ns:'sign-in'})}
                    </button>
                    <div class="text-center text-muted mt-3">
                        <a href="/forgot-password" tabindex="-1">{__('I forgot my password')}</a>
                    </div>
                </div>
            </div>
        </form>
        <div class="w-100 text-center text-muted mt-3">
            {__("Don't have account yet?")} <a href="/sign-up" tabindex="-1">{__('Sign up')}</a>
        </div>
    </div>

    <div class="modal modal-blur fade" id="modal-signin-error" tabindex="-1">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-status bg-danger"></div>
                <div class="modal-body text-center py-4">
                    <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24"
                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 9v2m0 4v.01" />
                        <path
                            d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
                    </svg>
                    <h3>{state.error.name}</h3>
                    <div class="text-muted">{state.error.message}</div>
                </div>
                <div class="modal-footer">
                    <div class="w-100">
                        <div class="row">
                            <div class="col">
                                <a href="#" class="btn w-100" data-bs-dismiss="modal">OK</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        import { router } from '@riotjs/route'

        export default {
            state: { error: { name: '', message: '' } },
            onBeforeMount(props, state) {
                this.auth = this.store.get('auth');
                this.auth.on('login-success', this.onLoginSuccess);
                this.auth.on('login-failed', this.onLoginFailed);
            },
            onMounted(props, state) {
                const identifier = this.auth.identifier;
                if (identifier) {
                    this.$('#identifier').value = identifier;
                }
                this.$('#identifier').focus();
            },
            onBeforeUpdate(rops, state) { },
            onUpdated(rops, state) {

            },
            onBeforeUnmount(rops, state) { },
            doLogin(e) {
                e.preventDefault();
                if (this.$('#submit'))
                    this.$('#submit').disabled = true;
                const credentials = {
                    identifier: this.$('#identifier').value,
                    password: this.$('#password').value,
                    remember_me: this.$('#remember-me').checked,
                    // pp_accepted: this.$('#accept-privacy-policy').checked,
                    // mode: this.mode
                };
                this.auth.login(credentials);
            },
            onLoginSuccess(user) {
                if (this.$('#submit'))
                    this.$('#submit').disabled = false;

                this.store.set('user', user);
                console.log('Logged IN ...');
                router.push('/', 'Home');
            },
            onLoginFailed(error) {
                let message = error.message;
                if (this.$('#submit'))
                    this.$('#submit').disabled = false;
                const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-signin-error'));
                if (error.data && error.data.error.message) {
                    message = error.data.error.message;
                }
                this.update({ error: { name: this.__('Login failed'), message: this.__(message) } });
                modal.show();
            },
            togglePassword(e) {
                e.preventDefault();
                const target = e.currentTarget.dataset.target;
                if (target) {
                    const field = this.$('#' + target);
                    if (field) {
                        field.type = (field.type === 'password') ? 'text' : 'password';
                    }
                }
            }
        }
    </script>
</sign-in>