<raw-html>
    <script>
        export default {
            setInnerHTML(date) {
                const now = new Date(date);
                this.root.innerHTML = now.getHours() + ':' + now.getMinutes();
            },
            onMounted(props, state) {
                this.setInnerHTML(props.date);
            },
            onUpdated(props, state) {
                this.setInnerHTML(props.date);
            }
        }
    </script>
</raw-html>