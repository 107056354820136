<span-html>
    <span id="span-html"></span>
    <script>
        export default {
            setInnerHTML(html) {
                const span = document.getElementById('span-html');
                span.innerHTML = html;
            },
            onMounted(props, state) {
                this.setInnerHTML(props.html)
            },
            onUpdated(props, state) {
                this.setInnerHTML(props.html)
            }
        }
    </script>
</span-html>
