import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import '@riotjs/hot-reload';
import { initDomListeners, setBase } from '@riotjs/route';
import * as riot from 'riot';
import { component } from 'riot';

import '#lib/DateUtils';
import '#lib/ObjectUtils';
import spinner from '#lib/Spinner';
import splash from '#lib/SplashScreen';
import store from '#lib/Store';
import '#lib/StringUtils';
import Toaster from '#lib/Toaster';
import App from './front-end.riot';
import registerGlobalComponents from './register-global-components';
import registerPages from './register-pages';

import en from './locales/en.json';
import fr from './locales/fr.json';

import '@tabler/core/dist/css/tabler.css';
import '@tabler/core/dist/js/tabler.js';
import 'izitoast/dist/css/iziToast.css';
import './style.css';

const { version } = require('../package.json');

splash.show();

i18next
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        ns: ['common'],
        defaultNS: 'common',
        resources: {
            en,
            fr
        },
        saveMissing: true
    })
    .then((t) => {
        const loc = window.location
        // const base = `${loc.pathname}/#`
        const base = '/';

        setBase(base);
        const domListener = initDomListeners()

        riot.install(function (component) {
            component.version = version;
            component.domListener = domListener;
            component.store = store;
            component.spinner = spinner;
            component.toaster = Toaster;
            component.__ = (...args) => i18next.t.apply(i18next, args);
        });

        i18next.on('missingKey', (lngs, namespace, key, res) => {
            console.log('i18next onMissingKey', lngs, namespace, key, res);
        });

        /*
        if (window.location.hash) {
            window.location.hash = '';
            window.location.hash.replace('#', '');
        }
        */

        // register
        registerGlobalComponents();

        registerPages();

        // mount the root tag
        component(App)(document.getElementById('root'));
    })
    .catch((error) => {
        console.error(error)
        splash.hide();
        const message = `An error has occured: ${error.status}`;
        throw new Error(message);
    });
