<forgot-password>
  <div class="container-tight py-4">
    <div class="text-center mb-2">
      <a href="." class="navbar-brand navbar-brand-autodark"><img src="assets/img/realives-logo-black.svg" height="36"
          alt=""></a>
    </div>
    <form class="card card-md" action="." method="get">
      <div class="card-body">
        <h2 class="card-title text-center mb-4">{__('Forgot password')}</h2>
        <p class="text-muted mb-4">
          {__('Enter your email address and your password will be reset and emailed to you')}.
        </p>
        <div class="mb-3">
          <label class="form-label">{__('Email address')}</label>
          <input id="email" type="email" class="form-control" placeholder="{__('Enter email')}">
        </div>
        <div class="form-footer">
          <button id="submit" type="button" class="btn btn-primary w-100" tabindex="4" onclick="{resetPassword}">
            <!-- Download SVG icon from http://tabler-icons.io/i/mail -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
              stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="3" y="5" width="18" height="14" rx="2" />
              <polyline points="3 7 12 13 21 7" />
            </svg>
            {__('Send me new password')}
          </button>
        </div>
      </div>
    </form>
    <div class="text-center text-muted mt-3">
      Forget it, <a href="/sign-in">send me back</a> to the sign in screen.
    </div>
  </div>

  <div id="modal-forgot-success" class="modal" tabindex="-1">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-status bg-success"></div>
        <div class="modal-body text-center py-4">
          <!-- Download SVG icon from http://tabler-icons.io/i/circle-check -->
          <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-green icon-lg" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
          <h3>Succedeed</h3>
          <div class="text-muted">
            An e-mail have been sent tou your mailbox.
          </div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col">
                <a href="#" class="btn w-100" data-bs-dismiss="modal">
                  Go to sign in screen
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    import { router } from '@riotjs/route';
    import axios from 'axios';

    export default {
      state: { error: { name: '', message: '' } },
      onBeforeMount(rops, state) {
        const config = this.store.get('config');
        axios.defaults.baseURL = config.api_uri;
        // axios.defaults.headers.common['Authorization'] = '';
      },
      onMounted(props, state) { },
      onBeforeUpdate(rops, state) { },
      onUpdated(rops, state) { },
      onBeforeUnmount(rops, state) { },
      resetPassword(e) {
        e.preventDefault();
        if (this.$('#submit'))
          this.$('#submit').disabled = true;

        const data = {
          "email": this.$('#email').value
        };

        const auth = this.store.get('auth');
        auth.on('reset-email-sent', () => { });
        auth.on('reset-email-failed', () => { });
        auth.forgot(data);
      },
      displayError(error) {
        if (this.$('#submit'))
          this.$('#submit').disabled = false;
        const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-signin-error'));
        this.update({ error: { name: 'Login failed', message: error.message } });
        modal.show();
      }
    }
  </script>
</forgot-password>