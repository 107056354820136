"use strict";

import observable from '@riotjs/observable';
import axios from 'axios';
// import jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";

const jwt_decode = jwtDecode;

class Auth {
    constructor(auth_endpoint, debug = false) {
        observable(this);
        this.$DEBUG = debug;
        this.__$token = this.token;
        this.__$user = this.user;

        // this.auth_endpoint = auth_endpoint;
        // this.auth_uri = auth_endpoint + '/auth/local';
        axios.defaults.baseURL = auth_endpoint;
        Object.seal(this);
    }

    isLogged() {
        const expired = this.isExpired(this.token);
        if (!expired) {
            if (!axios.defaults.headers.common['Authorization'] || axios.defaults.headers.common['Authorization'] == '') {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            }
        }
        return !expired;
    }

    isExpired(token) {
        if (!token) {
            return true;
        }
        const tk = jwt_decode(token);
        console.log('Auth::isExpired [tk]', tk);
        const now = Math.floor(Date.now() / 1000);
        //console.log('Auth::isExpired [now]', now);
        console.log(`tk.exp: ${tk.exp}; now:${now} remaining: ${tk.exp}-${now}`);
        if (tk && tk.exp < now) {
            console.log('Auth::isExpired TRUE');
            return true;
        }
        return false;
    }

    get identifier() {
        return localStorage.getItem('identifier');
    }

    set token(token) {
        this.__$token = token;
        if (this.__$token) {
            sessionStorage.setItem('token', this.__$token);
        } else {
            sessionStorage.removeItem('token');
        }
    }

    get token() {
        // try loading from localStorage
        if (!this.__$token)
            this.__$token = sessionStorage.getItem('token');
        if (this.isExpired(this.__$token)) {
            // token expired
            sessionStorage.removeItem('token');
            this.__$token = null;
        }
        return this.__$token
    }

    set user(user) {
        this.__$user = user;
        if (this.__$user) {
            sessionStorage.setItem('user', JSON.stringify(this.__$user));
        } else {
            sessionStorage.removeItem('user');
        }
    }

    get user() {
        this.__$user = JSON.parse(sessionStorage.getItem('user'));
        return this.__$user;
    }

    login(credentials) {
        const self = this;
        this.token = null;
        this.user = null;
        delete axios.defaults.headers.common["Authorization"];

        if (credentials.remember_me)
            localStorage.setItem('identifier', credentials.identifier);
        delete (credentials.remember_me);

        axios.post('/auth/local', { identifier: credentials.identifier, password: credentials.password })
            .then(response => {
                // Handle success.
                if (this.$DEBUG) {
                    console.log('User profile', response.data.user);
                    console.log('User token', response.data.jwt);
                }
                self.token = response.data.jwt;
                self.user = response.data.user;
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + self.token;
                self.trigger('login-success', response.data.user);
            }).catch(error => {
                if (this.$DEBUG) {
                    console.error('Login error', error.response);
                }
                self.trigger('login-failed', error.response);
            });
    }

    register(credentials) {
        axios.post('/auth/local/register', {
            username: 'Strapi user',
            email: 'user@strapi.io',
            password: 'strapiPassword',
        }).then(response => {
            // Handle success.
            console.log('Well done!');
            console.log('User profile', response.data.user);
            console.log('User token', response.data.jwt);
        }).catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
        });
    }

    forgot(data) {
        const self = this;
        axios.post('/auth/forgot-password', data)
            .then(response => {
                if (this.$DEBUG) {
                    console.log('forgot-password response:', response);
                }
                self.trigger('reset-email-sent');
            }).catch(error => {
                console.log('An error occurred:', error);
                self.trigger('reset-email-failed');
            });
    }

    reset(data) {
        const self = this;
        axios.post('/auth/reset-password', data)
            .then(response => {
                self.trigger('password-reset-success');
                console.log("Your user's password has been reset.");
            }).catch(error => {
                self.trigger('password-reset-failed');

                console.log('An error occurred:', error.response);
            });
    }

    logout() {
        const self = this;
        console.log('Log OUT!');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        this.token = null;
        this.user = null;
        delete axios.defaults.headers.common["Authorization"];
        self.trigger('logged-out');
        return false;
    }

    onError(error) {
        // console.error('Exception Error:', error);
        try {
            if (error.response) {
                if (error.response.status == 401 || error.response.status == 403) {
                    this.store.trigger('logged-out');
                } else {
                    if (error.response.data.error.details) {
                        const messages = [];
                        const errors = error.response.data.error.details.errors;
                        errors.forEach((e) => { messages.push(`<li>${e.message}</li>`) });
                        this.toaster.error('ERROR', `<ul>${messages.join('')}</ul>`);
                    } else {
                        this.toaster.error('ERROR', error.response.data.error.message);
                    }
                }
            } else if (error.request) {
                this.toaster.error('Error', 'No response received from the server.');
            } else if (error.message) {
                this.toaster.error('Error', error.message);
            } else {
                this.toaster.error('Error', 'An undefined error occured while processing your request');
            }
        } catch (ex) {
            this.toaster.error('Error', 'An error occured while processing error<br>' + ex.message);
        }
    }

}

export default Auth;
