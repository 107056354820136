<page-footer>
    <div class="container-xl mb-1">
        <div class="row text-center align-items-center flex-row-reverse">
            <div class="col-lg-auto ms-lg-auto">


            </div>
            <div class="col-12 mt-3 mt-lg-0">
                <ul class="list-inline list-inline-dots mb-0">
                    <li class="list-inline-item">
                        Copyright &copy; 2020-{currentYear}
                        <a href="https://realives.com/" target="_blank" class="link-secondary ms-1">REALIVES</a>.
                        {__('All rights reserved')}.
                    </li>
                    <li class="list-inline-item">
                        v{version}-alpha
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                let dt = new Date();
                this.currentYear = dt.getFullYear();
            },
            onMounted(props, state) { },
            onBeforeUpdate(props, state) { },
            onUpdated(props, state) { },
            onBeforeUnmount(props, state) { }
        }
    </script>
</page-footer>