'use strict';

class BitSet {
    constructor(value = null) {
        //
        const WORD_BIT = 31;

        const BITS = '1'.padStart(WORD_BIT, '1');
        this.MAX_VALUE = parseInt(BITS, 2);
        this.MAX_POSITION = WORD_BIT;

        if (!value) {
            this.n = 0;
        } else {
            if (!Number.isSafeInteger(value)) throw new TypeError('value must be a safe integer');
            if (Math.sign(value) == -1) throw new TypeError('value must be a positive or unsigned integer');
            if (value > this.MAX_VALUE) throw new RangeError('value must be <= ' + this.MAX_VALUE);
            this.n = value;
        }
    }

    get = () => {
        return this.n;
    }

    set = (p) => {
        const self = this;
        if (Array.isArray(p)) {
            p.forEach((v) => {
                if (v < 0 || v > this.MAX_POSITION) throw new RangeError('value' + v + ' must be between 1 and ' + this.MAX_POSITION);
                self.n |= (1 << v);
            });
        } else {
            if (p < 0 || p > this.MAX_POSITION) throw new RangeError('value' + p + ' must be between 1 and  ' + this.MAX_POSITION);
            this.n |= (1 << p);
        }
        return this;
    }

    has = (p) => {
        if (p < 0 || p > this.MAX_POSITION) throw new RangeError('value ' + p + ' must be between 1 and  ' + this.MAX_POSITION);
        return (this.n & (1 << p)) !== 0;
    }

    contains = (p) => {
        if (p < 0 || p > this.MAX_POSITION) throw new RangeError('value ' + p + ' must be between 1 and  ' + this.MAX_POSITION);
        return (this.n & (1 << p)) !== 0;
    }

    clear = (p) => {
        this.n &= ~(1 << p);
        return this;
    }

    reset = (p) => {
        this.n = 0;
        return this;
    }

    toggle = (p) => {
        if (p < 0 || p > this.MAX_POSITION) throw new RangeError('value ' + p + ' must be between 1 and  ' + this.MAX_POSITION);
        this.n ^= (1 << p);
        return this;
    }
}

export default BitSet;