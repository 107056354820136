<raw-html>
    <script>
        export default {
            setInnerHTML(html) {
                this.root.innerHTML = html;
            },
            onMounted(props, state) {
                this.setInnerHTML(props.html);
            },
            onUpdated(props, state) {
                this.setInnerHTML(props.html);
            }
        }
    </script>
</raw-html>