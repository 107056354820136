<lang-selector>

    <label class="form-label">Tags input</label>
    <select type="text" class="form-select tomselected ts-hidden-accessible" placeholder="Select tags" id="select-tags"
        value="" multiple="multiple" tabindex="-1">



        <option value="jQuery">jQuery</option>
        <option value="Bootstrap">Bootstrap</option>
        <option value="Ruby">Ruby</option>
        <option value="Python">Python</option>
        <option value="JavaScript">JavaScript</option>
        <option value="HTML">HTML</option>
        <option value="CSS">CSS</option>
    </select>
    <div class="ts-wrapper form-select multi focus">
        <div class="ts-control"><input tabindex="0" role="combobox" aria-haspopup="listbox" aria-expanded="false"
                aria-controls="select-tags-ts-dropdown" id="select-tags-ts-control" placeholder="Select tags"
                type="select-multiple" aria-activedescendant="select-tags-opt-3"></div>
    </div>





    <span class="tag">
        United Arab Emirates
        <a href="#" class="btn-close"></a>
    </span>

    <div class="form-group">
        <label>Tagsinput Autocomplete</label>
        <input type="text" id="tag1" class="form-control" />
    </div>

    <div class="mb-3">
        <div class="form-label">Select multiple states</div>
        <select type="text" class="form-select tomselected ts-hidden-accessible" id="select-states" value=""
            multiple="multiple" tabindex="-1">
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>

            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>

            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>

            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>

            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>

            <option value="WY" selected="">Wyoming</option>
            <option value="AZ" selected="">Arizona</option>
            <option value="SC" selected="">South Carolina</option>
            <option value="NY">New York</option>
            <option value="TX">Texas</option>
        </select>
        <div class="ts-wrapper form-select multi has-items">
            <div class="ts-control">
                <div data-value="AZ" class="item" data-ts-item="">Arizona</div>
                <div data-value="SC" class="item" data-ts-item="">South Carolina</div>
                <div data-value="NY" class="item" data-ts-item="">New York</div>
                <div data-value="TX" class="item" data-ts-item="">Texas</div><input tabindex="0" role="combobox"
                    aria-haspopup="listbox" aria-expanded="false" aria-controls="select-states-ts-dropdown"
                    id="select-states-ts-control" type="select-multiple">
            </div>
        </div>
    </div>

    <script>
        // imports goes here

        export default {
            state: {},
            // All unused methods should be removed
            onBeforeMount(props, state) { },
            onMounted(props, state) { },
            shouldUpdate(newProps, currentProps) {
                return true;
            },
            onBeforeUpdate(props, state) { },
            onUpdated(props, state) { },
            onBeforeUnmount(props, state) { },
            onUnmounted(props, state) { },
            // other component methods goes here
        }
    </script>
</lang-selector>