/**
 * 
 */

export default [
    {
        path: '/',
        label: 'Home',
        componentName: 'home-page',
        wrapped: true,
        menu: true,
        auth: true
    },
    {
        path: '/sign-in',
        label: 'Sign In',
        componentName: 'sign-in',
        preloaded: true,
        wrapped: false,
        menu: false,
        auth: false
    },
    {
        path: '/sign-up',
        label: 'Sign Up',
        componentName: 'sign-up',
        preloaded: true,
        wrapped: false,
        menu: false,
        auth: false
    },
    {
        path: '/forgot-password',
        label: 'Forgot Password',
        componentName: 'forgot-password',
        preloaded: true,
        wrapped: false,
        menu: false,
        auth: false
    },
    {
        path: '/reset-password',
        label: 'Reset Password',
        componentName: 'reset-password',
        preloaded: true,
        wrapped: false,
        menu: false,
        auth: false
    },
    {
        path: '/user-profile',
        label: 'User Profile',
        componentName: 'user-profile',
        preloaded: true,
        wrapped: true,
        menu: true,
        auth: true
    },
    {
        path: '/terms-of-service',
        label: 'Terms of Service',
        componentName: 'terms-of-service',
        preloaded: true,
        wrapped: true,
        menu: true,
        auth: true
    },
    {
        path: '/contact-us',
        label: 'Contact Us',
        preloaded: true,
        componentName: 'contact-us',
        wrapped: true,
        menu: true,
        auth: true
    },
    {
        path: '/bug-report',
        label: 'Bug Report',
        componentName: 'bug-report',
        preloaded: true,
        wrapped: true,
        menu: true,
        auth: true
    },
    {
        path: '/about',
        label: 'About',
        componentName: 'about',
        preloaded: true,
        wrapped: true,
        menu: true,
        auth: true
    }
]
