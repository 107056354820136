<p-html>
    <p id="p-html"></p>
    <script>
        export default {
            setInnerHTML(html) {
                const p = document.getElementById('p-html');
                p.innerHTML = html;
            },
            onMounted(props, state) {
                this.setInnerHTML(props.html)
            },
            onUpdated(props, state) {
                this.setInnerHTML(props.html)
            }
        }
    </script>
</p-html>
