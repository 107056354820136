<header-bar>
    <header class="navbar navbar-expand-md navbar-light d-print-none">
        <div class="container-xl">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                <span class="navbar-toggler-icon"></span>
            </button>
            <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                <a href="/">
                    <img src="/assets/img/realives-logo-black.svg" width="110" height="32" alt="Tabler"
                        class="navbar-brand-image">
                </a>
            </h1>
            <div class="navbar-nav flex-row">
                <a href="/" class="nav-link px-0" title="Home" data-bs-toggle="tooltip" data-bs-placement="bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                    </svg>
                </a>
            </div>
            <div class="navbar-nav flex-row order-md-last">
                <a href="/bug-report" class="nav-link px-0" title="Bug report" data-bs-toggle="tooltip"
                    data-bs-placement="bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 9v-1a3 3 0 0 1 6 0v1"></path>
                        <path d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3"></path>
                        <path d="M3 13l4 0"></path>
                        <path d="M17 13l4 0"></path>
                        <path d="M12 20l0 -6"></path>
                        <path d="M4 19l3.35 -2"></path>
                        <path d="M20 19l-3.35 -2"></path>
                        <path d="M4 7l3.75 2.4"></path>
                        <path d="M20 7l-3.75 2.4"></path>
                    </svg>
                </a>

                <a href="#" class="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" onclick="{setDarkTheme}">
                    <!-- Download SVG icon from http://tabler-icons.io/i/moon -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
                    </svg>
                </a>
                <a href="#" class="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" onclick="{setLightTheme}">
                    <!-- Download SVG icon from http://tabler-icons.io/i/sun -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="4" />
                        <path
                            d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" />
                    </svg>
                </a>
                <div class="nav-item dropdown">
                    <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                        aria-label="Open user menu">
                        <span class="avatar avatar-sm avatar-rounded" style="background-image: url({avatar})"
                            if={avatar}></span>
                        <span class="avatar avatar-md bg-transparent" if={!user.avatar}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-circle"
                                width="48" height="48" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
                            </svg>
                        </span>
                        <div class="d-none d-xl-block ps-2">
                            <div>{user.username}</div>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <a href="/user-profile" class="dropdown-item">{__('Profile & account')}</a>
                        <a href="/contact-us" class="dropdown-item">{__('Contact US')}</a>
                        <a href="/terms-of-service" class="dropdown-item">{__('Terms of Service')}</a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item" onclick="{doLogout}">{__('Logout')}</a>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <script>
        import { router } from '@riotjs/route';

        export default {
            onBeforeMount(props, state) {
                const Config = this.store.get('config');
                this.auth = this.store.get('auth');
                this.avatar = null;
                this.user = this.auth.user;

                try {
                    this.avatar = Config.assets_base + this.user.avatar.url;
                } catch (error) {

                }
            },
            onMounted(props, state) {
            },
            doLogout(e) {
                const self = this;
                // this.toaster.show();
                this.toaster.info('Good Bye', 'You\'ve been sucessfully disconnected.<br>You\'ll be now redirected to the home.');
                this.auth.logout();
                router.push('/', 'Home');
            },
            setDarkTheme(e) {
                e.preventDefault();
                e.stopPropagation();
                const body = document.querySelector('body');
                body.classList.toggle('theme-light', false);
                body.classList.toggle('theme-dark', true);
            },
            setLightTheme(e) {
                e.preventDefault();
                e.stopPropagation();
                const body = document.querySelector('body');
                body.classList.toggle('theme-dark', false);
                body.classList.toggle('theme-light', true);
            },
        }
    </script>
</header-bar>