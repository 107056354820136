
export default [
    { name: 'users', label: 'Clients', icon: 'broadcast', path: '/client', target: 'client-plugin' },
    { name: 'streaming', label: 'Streaming', icon: 'broadcast', path: '/stream', target: 'stream-plugin' },
    { name: 'vod', label: 'VOD', icon: 'vod', path: '/vod/list', target: 'vod-list' },
    { name: 'event', label: 'Event', icon: 'network', path: '/event/list', target: 'event-list' },
    { name: 'simulator', label: 'Simulateur', icon: 'simulator', path: '/simulator', target: 'simulator-plugin' },
    { name: 'interactivity', label: 'Interactivité', icon: 'game', path: '/interactivity-list', target: 'interactivity-list' },
    { name: 'simulator', label: 'Simulateur', icon: 'simulator', path: '/simulator', target: 'simulator-plugin' },
    { name: 'diagnostic', label: 'Diagnostic', icon: 'diagnostic', path: '/diagnostic', target: 'diagnostic-plugin' },
    { name: 'whip', label: 'Multi-site', icon: 'broadcast', path: '/multi-site', target: 'multi-site' },
    { name: 'srt', label: 'SRT', icon: 'broadcast', path: '/srt', target: 'srt' },
    { name: 'connection_tester', label: 'Test User cnx', icon: 'cnxtest', path: '/connection-tester', target: 'connection-tester' },
    { name: 'load_simulator', label: 'Load Test', icon: 'loadtest', path: '/load-simulator', target: 'load-simulator' }
];