<color-picker>
    <div id="{pickrId}"></div>
    <input type="hidden" id="{state.id}" value="{state.color}" />

    <script>
        import Pickr from '@simonwep/pickr';
        import '@simonwep/pickr/dist/themes/nano.min.css';
        import './color-picker.css';

        export default {
            onBeforeMount(props, state) {
                this.pickr = null;
                this.pickrId = 'pickr-' + this.randomNumber(100, 999);
                this.state = {
                    id: props.fieldId || 'field-' + this.randomNumber(100, 999),
                    color: props.color || '#000000'
                };
                if (this.props.onColorChanged && typeof this.props.onColorChanged == 'function') {
                    this.onColorChanged = this.props.onColorChanged;
                } else {
                    // console.log('Type of onFileSelected:' + typeof this.props.onFileSelected);
                }
                this.dirty = false;
            },
            onMounted(props, state) {
                const self = this;
                this.pickr = Pickr.create({
                    // el: '.form-control-color',
                    el: '#' + this.pickrId,
                    theme: 'nano', // or 'monolith', or 'nano'
                    comparison: true,
                    adjustableNumbers: true,
                    default: this.state.color,
                    // swatches: null,
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,
                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: false,
                            cmyk: true,
                            input: true,
                            clear: false,
                            cancel: true,
                            save: true
                        }
                    }
                });

                this.pickr.on('init', instance => {
                    // console.log('Event: "init"', instance);
                }).on('hide', instance => {
                    // console.log('Event: "hide"', instance);
                    if (this.dirty && this.onColorChanged) {
                        this.onColorChanged(this.state.id, this.state.color);
                    }
                }).on('show', (color, instance) => {
                    // this.state.color = color.toHEXA().toString();
                    // console.log('Event: "show"', color, instance);
                }).on('save', (color, instance) => {
                    // console.log('Event: "save"', color, instance);
                    this.dirty = false;
                    this.update({ color: color.toHEXA().toString() });
                    if (this.onColorChanged) {
                        this.onColorChanged(this.state.id, this.state.color);
                    }
                    instance.hide();
                }).on('clear', instance => {
                    console.log('Event: "clear"', instance);
                }).on('change', (color, source, instance) => {
                    // console.log('Event: "change"', color, source, instance);
                    this.dirty = true;
                    if (this.onColorChanged) {
                        this.onColorChanged(this.state.id, color.toHEXA().toString());
                    }
                }).on('changestop', (source, instance) => {
                    // console.log('Event: "changestop"', source, instance);
                }).on('cancel', instance => {
                    // console.log('Event: "cancel"', instance);
                    this.dirty = false;
                    if (this.onColorChanged) {
                        this.onColorChanged(this.state.id, this.state.color);
                    }
                    instance.hide();
                }).on('swatchselect', (color, instance) => {
                    //console.log('Event: "swatchselect"', color, instance);
                });
            },
            shouldUpdate(newProps, currentProps) {
                return true;
            },
            onBeforeUpdate(props, state) { },
            onUpdated(props, state) { },
            onBeforeUnmount(props, state) {
                if (this.pickr) {
                    this.pickr.destroyAndRemove();
                }
            },
            onUnmounted(props, state) { },
            randomNumber(min, max) {
                return Math.floor(Math.random() * (1 + max - min) + min);
            },
        }

    </script>
</color-picker>
