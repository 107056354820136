if (!Date.getAsDateField) {
    Date.prototype.getAsDateField = function () {
        return [
            this.getFullYear(),
            (this.getMonth() + 1).toString().padStart(2, '0'),
            (this.getDate()).toString().padStart(2, '0'),
        ].join('-');
    }
}
if (!Date.getAsTimeField) {
    Date.prototype.getAsTimeField = function () {
        return [(this.getHours()).toString().padStart(2, '0'),
        (this.getMinutes()).toString().padStart(2, '0'),
        ].join(':');
    }
}

if (!Date.getAsDateTimeField) {
    Date.prototype.getAsDateTimeField = function () {
        return (
            [
                this.getFullYear(),
                (this.getMonth() + 1).toString().padStart(2, '0'),
                (this.getDate()).toString().padStart(2, '0'),
            ].join('-') + 'T' + [
                (this.getHours()).toString().padStart(2, '0'),
                (this.getMinutes()).toString().padStart(2, '0'),
            ].join(':')
        );
    }
}

if (!Date.toInputValue) {
    Date.prototype.toInputValue = function () {
        // console.log('UTC Date', this.toUTCString());
        // console.log('Locale Date', this.toLocaleDateString());
        return (
            [
                this.getFullYear(),
                (this.getMonth() + 1).toString().padStart(2, '0'),
                (this.getDate()).toString().padStart(2, '0'),
            ].join('-') + 'T' + [
                (this.getHours()).toString().padStart(2, '0'),
                (this.getMinutes()).toString().padStart(2, '0'),
            ].join(':')
        );
    }
}

if (!Date.friendlyDate) {
    Date.prototype.friendlyDate = function () {
        return (
            [
                this.getFullYear(),
                (this.getMonth() + 1).toString().padStart(2, '0'),
                (this.getDate()).toString().padStart(2, '0'),
            ].join('-') + ' ' + [
                (this.getHours()).toString().padStart(2, '0'),
                (this.getMinutes()).toString().padStart(2, '0'),
            ].join(':')
        );
    }
}

if (!Date.isBetween) {
    Date.prototype.isBetween = function (minDate, maxDate) {
        if (!this.getTime) throw new Error('isBetween() was called on a non Date object');
        if (!minDate.getTime) throw new Error('minDate must be a Date object');
        if (!maxDate.getTime) throw new Error('maxDate must be a Date object');
        return !minDate ? true : this.getTime() >= minDate.getTime()
            && !maxDate ? true : this.getTime() <= maxDate.getTime();
    };
}
