"use strict";

import observable from '@riotjs/observable';

class Store {
    constructor() {
        observable(this);
        this.props = {};
    }

    set(prop, value) {
        const action = (this.props[prop] != 'undefined') ? 'updated' : 'created';
        this.props[prop] = value;
        this.trigger(prop + '-' + action, value);
    }

    get(prop, deflt = undefined) {
        if (this.props[prop]) {
            return this.props[prop];
        }
        return deflt;
    }

    has(prop) {
        return (this.props[prop] !== undefined);
    }

    delete(prop) {
        if (this.props[prop]) {
            delete this.props[prop];
        }
        this.trigger(prop + '-deleted', undefined);
    }
}

const store = new Store();
export default store;
