'use strict';

import BitSet from '#lib/BitSet';
import Role from '../config/Roles';

const bs = new BitSet();

const RolesMap = new Map();

RolesMap.set(bs.set(Role.HOSTMASTER).get(), 'HOSTMASTER');
RolesMap.set(bs.set(Role.EMPLOYEE).get(), 'EMPLOYEE');
RolesMap.set(bs.set(Role.CLIENT).get(), 'CLIENT');
RolesMap.set(bs.set(Role.MASTER).get(), 'MASTER');
RolesMap.set(bs.set(Role.PGM).get(), 'PGM');
RolesMap.set(bs.set(Role.PUBLISHER).get(), 'PUBLISHER');
RolesMap.set(bs.set(Role.MODERATOR).get(), 'MODERATOR');
RolesMap.set(bs.set(Role.JOURNALIST).get(), 'JOURNALIST');
RolesMap.set(bs.set(Role.SYNTHE).get(), 'SYNTHE');
RolesMap.set(bs.set(Role.SPEAKER).get(), 'SPEAKER');
RolesMap.set(bs.set(Role.PANELIST).get(), 'PANELIST');
RolesMap.set(bs.set(Role.GUEST).get(), 'GUEST');
RolesMap.set(bs.set(Role.VIEWER).get(), 'VIEWER');
RolesMap.set(0, 'PUBLIC');

export default RolesMap;
